/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.logo {
    width: 100px;
    height: auto;
    display: block;
    margin: 0 auto 20px;
}


/* Ensure the body and html take the full viewport height */
html, body {
    height: 100%;
    font-family: "Arial", sans-serif;
    line-height: 1.6;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

/* Light and Dark Modes */
body.dark {
    background: linear-gradient(180deg, #1e1f25, #121212);
    color: white;
}

body.light {
    background: linear-gradient(180deg, #f7f7f7, #eaeaea);
    color: #333;
}

/* Prevent white peeking due to scroll bounce */
body::after {
    content: '';
    display: block;
    height: 100vh;
    background: inherit; /* Matches body background */
    position: fixed;
    width: 100%;
    z-index: -1;
}

/* Main Container */
.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

main {
    flex-grow: 1; /* Pushes the footer to the bottom when content is insufficient */
}

/* Hero Section */
.hero {
    text-align: center;
    padding: 60px 20px;
    margin-bottom: 40px;
}

.hero-content h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.hero-content p {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.hero-content h1,
.hero-content p {
    color: inherit;
}

/* Hero Section Buttons */
.button-group {
    display: flex;
    justify-content: center;
    gap: 20px; /* Space between buttons */
}

.cta-button {
    background: #5865f2;
    border: none;
    color: white;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    transition: transform 0.2s ease, background 0.3s ease;
}

.cta-button:hover {
    background: #404eed;
    transform: translateY(-2px);
}

.cta-button.secondary {
    background: #404eed; /* Same bright blue for consistency */
}

.cta-button.secondary:hover {
    background: #404eed; /* Matches hover effect of primary button */
}

/* Popup Modal */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
    background: inherit;
    color: inherit;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, -60%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

/* Overlay to fade out the page */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
    z-index: 999; /* Layer above the content */
    opacity: 1;
    transition: opacity 0.3s ease;
}

/* Why Us Section */
.why-us {
    text-align: center;
    margin-bottom: 40px;
}

.why-us h2 {
    font-size: 2rem;
    color: #5865f2;
    margin-bottom: 20px;
}

.reasons-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three items per row on larger screens */
    gap: 20px;
    justify-content: center; /* Center the grid items */
}

@media (max-width: 768px) {
    .reasons-grid {
        grid-template-columns: repeat(2, 1fr); /* Two items per row on medium screens */
    }
}

@media (max-width: 480px) {
    .reasons-grid {
        grid-template-columns: 1fr; /* One item per row on small screens */
    }
}

.reason-card, .service-card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    text-align: left;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s, color 0.3s;
    background: inherit;
    color: inherit;
}

.reason-card h3, .service-card h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #5865f2;
}

/* Light Mode Specifics */
body.light .reason-card,
body.light .service-card,
body.light .contact-form {
    background: #ffffff;
    color: #333; /* Ensure dark text for readability */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.reason-card p, .service-card p {
    font-size: 1rem;
    color: inherit;
}

/* Hover Animation */
.reason-card:hover, .service-card:hover {
    transform: scale(1.05) rotate(-1deg);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
}

.reason-card:hover::after, .service-card:hover::after {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(circle, rgba(88, 101, 242, 0.2) 50%, transparent 100%);
    animation: wave 1.2s ease-in-out infinite;
}

/* Wave Animation */
@keyframes wave {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.03);
    }
}

/* Disable Hover Animation on Mobile */
@media (max-width: 768px) {
    .reason-card:hover, .service-card:hover {
        transform: none; /* Disable scaling and rotation */
        box-shadow: none; /* Remove hover shadow effect */
    }

    .reason-card:hover::after, .service-card:hover::after {
        content: none; /* Disable the wave animation */
    }
}

/* Services Section */
.services {
    text-align: center;
    margin-bottom: 40px;
}

.services h2, .why-us h2 {
    font-size: 2rem;
    color: #5865f2;
}

.service-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

/* Contact Section */
.contact {
    text-align: center;
    margin-bottom: 40px;
}

.contact h2 {
    font-size: 2rem;
    color: #5865f2;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    background: inherit;
    color: inherit;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.form-group label {
    font-weight: bold;
    color: inherit;
}

.form-group input,
.form-group textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #333;
    background: inherit;
    color: inherit;
    font-size: 1rem;
}

.form-group textarea {
    resize: none;
    height: 100px;
}

/* Radio Button Section */
.form-group-radio {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Radio Options Container */
.radio-options {
    display: flex; /* Align items in a row */
    flex-wrap: wrap; /* Allow wrapping if necessary */
    justify-content: center; /* Center the options */
    gap: 10px; /* Add spacing between options */
}

.radio-title {
    font-weight: bold;
    color: inherit;
    margin-bottom: 5px;
}

/* General Radio Button Container Styles */
.radio-option {
    position: relative;
}

.radio-option input {
    display: none; /* Hide the native radio input */
}

/* Label Styles (Box) */
.radio-option label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    min-width: 120px; /* Consistent size */
    background-color: #121212; /* Default dark mode background */
    color: white; /* Default dark mode text */
    border: 1px solid #333; /* Subtle border */
    transition: all 0.3s ease; /* Smooth transitions */
}

/* Light Mode Radio Button Box */
body.light .radio-option label {
    background-color: #f9f9f9; /* Light mode background */
    color: #333; /* Light mode text */
    border: 1px solid #ddd; /* Light mode border */
}

/* Hover Effect */
.radio-option label:hover {
    background-color: #404eed; /* Accent color for hover */
    color: white; /* Ensure readability */
    border-color: #5865f2; /* Accent border */
}

/* Selected (Checked) Styles */
.radio-option input:checked + label {
    background-color: #5865f2; /* Selected background color */
    color: white; /* High contrast text */
    font-weight: bold; /* Emphasize selection */
    border-color: #404eed; /* Accent border */
}


/* Footer */
footer {
    text-align: center;
    padding: 20px;
    border-top: 1px solid #ccc;
    font-size: 0.9rem;
    position: relative;
    bottom: 0;
    width: 100%;
    margin-top: auto;
    background: inherit;
    color: inherit;
}

body.light footer {
    background: #eaeaea;
    color: #333;
}

body.dark footer {
    background: #121212;
    color: #ccc;
}

.graphic-container {
    text-align: center;
    margin: 20px 0;
}

.graphic-image {
    max-width: 80%;
    height: auto;
    margin: auto;
}

/* Toggle Container */
.theme-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    gap: 10px; /* Space between icons and toggle */
}

/* Sun and Moon Icons */
.theme-toggle .icon {
    font-size: 20px; /* Adjust icon size */
    transition: opacity 0.3s ease, color 0.3s ease;
}

.theme-toggle .icon.sun {
    color: #f9d71c; /* Sun color */
    opacity: 1; /* Sun is visible by default */
    transition: opacity 0.3s ease;
}

.theme-toggle .icon.moon {
    color: #5865f2; /* Moon color */
    opacity: 100; /* Moon is hidden by default */
    transition: opacity 0.3s ease;
}

/* Visibility Change on Toggle */
.theme-toggle input:checked ~ .icon.sun {
    opacity: 0; /* Hide sun */
}

.theme-toggle input:checked ~ .icon.moon {
    opacity: 1; /* Show moon */
}

/* Toggle Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}

/* Hide the Default Checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* Slider for the Switch */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc; /* Default background color */
    border-radius: 30px; /* Fully round slider */
    transition: background-color 0.3s ease;
}

/* Dark Mode Styling */
body.dark .slider {
    background-color: #333;
}

/* Circular Knob */
.slider:before {
    position: absolute;
    content: "";
    height: 24px; /* Knob height */
    width: 24px; /* Knob width */
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    top: 3px;
    left: 3px; /* Starting position on the left */
    transition: transform 0.3s ease;
}

/* Active State (Checked) */
input:checked + .slider {
    background-color: #5865f2; /* Accent color for active state */
}

input:checked + .slider:before {
    transform: translateX(30px); /* Move knob to the right */
}

.error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 5px;
}


/* Pricing Section */
.pricing-section {
    text-align: center;
    padding: 2rem;
    background: transparent; /* Matches surrounding design */
    margin-bottom: 3rem;
}

.pricing-section h2 {
    font-size: 2rem;
    color: #5865f2; /* Accent color for the title */
    margin-bottom: 1.5rem;
}

/* Pricing Card Container for Centering */
.pricing-card-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the card and button horizontally */
}

/* Smaller Pricing Card Styling */
.pricing-card {
    padding: 15px;
    width: 100%; /* Adjust size (smaller) */
    max-width: 400px; /* Ensure it's not too wide */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    text-align: left;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s, color 0.3s;
    background: inherit;
    color: inherit;
}

/* Dark Mode Specifics */
body.dark .pricing-card {
    background: inherit; /* Matches dark mode cards */
    color: #e0e0e0; /* Light text for dark mode */
}

/* Light Mode Specifics */
body.light .pricing-card {
    background: #ffffff; /* Light background for light mode */
    color: #333; /* Dark text for readability */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for light mode */
}

.pricing-card:hover {
    transform: scale(1.05); /* Slight hover effect for consistency */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.4); /* Matches reason card hover */
}

/* Disable Transform and Hover Effects on Mobile */
@media (max-width: 768px) {
    .pricing-card:hover {
        transform: none;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Maintain consistent shadow */
    }

    .pricing-card-container .cta-button:hover {
        transform: none; /* Disable button lift effect */
    }
}

.pricing-card p {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: inherit; /* Inherit color for light/dark mode */
}

.pricing-card ul {
    list-style: none;
    padding: 0;
    margin: 0 auto 1rem;
}

.pricing-card ul li {
    font-size: 1rem;
    color: inherit; /* Inherit color for light/dark mode */
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
}

.pricing-card ul li::before {
    content: "•";
    color: #5865f2; /* Accent bullet color */
    margin-right: 0.5rem;
}

/* Button Styling Below the Card */
.pricing-card-container .cta-button {
    display: inline-block;
    background-color: #5865f2;
    color: #ffffff;
    padding: 0.8rem 1.0rem;
    border-radius: 25px;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
    cursor: pointer;
    margin-top: 1rem;
}

.pricing-card-container .cta-button:hover {
    background-color: #404eed; /* Darker blue on hover */
    transform: translateY(-3px); /* Lift on hover */
}
